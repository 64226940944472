import { lazy } from 'react';
import { Navigate } from 'react-router';
import Loadable from 'ui-component/Loadable';
import { getCache } from 'utils/local-storage';

const Login = Loadable(lazy(() => import('views/auth/login')));

const accessToken = getCache('access_token');
const roleUser = getCache('role');
getCache('id_user');
getCache('name');

const urlHome = roleUser === 'ADM' ? '/backoffice/dashboard' : '/frontoffice/home';

// ==============================|| AUTHENTICATION ROUTING ||============================== //
const AuthenticationRoutes = {
  path: '/',
  children: [
    {
      index: true,
      element: accessToken ? <Navigate to={urlHome} /> : <Login />
    },
    {
      path: 'login',
      element: accessToken ? <Navigate to={urlHome} /> : <Login />
    }
  ]
};

export default AuthenticationRoutes;
