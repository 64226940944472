import { getCache } from 'utils/local-storage';
import backoffice from './backoffice';
import frontoffice from './frontoffice';

// ==============================|| MENU ITEMS ||============================== //
const roleUser = getCache('role');

const menuItems = {
  items: [roleUser === 'ADM' ? backoffice : frontoffice]
};

export default menuItems;
