import { IconCar, IconCreditCard, IconHome, IconList, IconReceipt } from '@tabler/icons';

const frontoffice = {
  id: 'frontoffice',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      url: '/frontoffice/home',
      icon: IconHome
    },
    {
      id: 'vehicle',
      title: 'Kendaraan',
      type: 'collapse',
      icon: IconCar,
      children: [
        {
          id: 'submission',
          title: 'Pengajuan',
          type: 'item',
          url: '/frontoffice/vehicle/submission'
        },
        {
          id: 'list',
          title: 'List',
          type: 'item',
          url: '/frontoffice/vehicle/list'
        },
        {
          id: 'list-detail',
          title: 'Detail Kendaraan',
          type: 'hidden',
          url: '^/frontoffice/vehicle/list/[0-9]+$'
        }
      ]
    },
    {
      id: 'parking-package',
      title: 'Paket Parkir',
      type: 'item',
      url: '/frontoffice/parking-package',
      icon: IconCreditCard
    },
    {
      id: 'payment',
      title: 'Pembayaran',
      type: 'item',
      url: '/frontoffice/payment',
      icon: IconReceipt
    },
    {
      id: 'history-subscription',
      title: 'Riwayat Berlangganan',
      type: 'item',
      url: '/frontoffice/history-subscription',
      icon: IconList
    }
  ]
};

export default frontoffice;
