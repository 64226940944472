// assets
import {
  IconCreditCard,
  IconDashboard,
  IconDatabase,
  // IconFileDescription,
  IconReport,
  IconSettings,
  IconUserPlus,
  IconUsers,
  IconWallet
} from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const backoffice = {
  id: 'backoffice',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/backoffice/dashboard',
      icon: IconDashboard
    },
    {
      id: 'user',
      title: 'Pengguna',
      type: 'collapse',
      icon: IconUsers,
      children: [
        {
          id: 'academic',
          title: 'Akademis',
          type: 'item',
          url: '/backoffice/user/academic'
        },
        {
          id: 'academic-detail',
          title: 'Detail Akademis',
          type: 'hidden',
          url: '^/backoffice/user/academic/[0-9]+$'
        },
        {
          id: 'partner',
          title: 'Mitra',
          type: 'item',
          url: '/backoffice/user/partner'
        },
        {
          id: 'partner-detail',
          title: 'Detail Mitra',
          type: 'hidden',
          url: '^/backoffice/user/partner/[0-9]+$'
        }
      ]
    },
    {
      id: 'subscription-package',
      title: 'Paket Langganan',
      type: 'collapse',
      icon: IconCreditCard,
      children: [
        {
          id: 'period',
          title: 'Periode Paket',
          type: 'item',
          url: '/backoffice/subscription-package/period'
        },
        {
          id: 'period-detail',
          title: 'Detail Periode Paket',
          type: 'hidden',
          url: '^/backoffice/subscription-package/period/[0-9]+$'
        },
        {
          id: 'list',
          title: 'List Paket Langganan',
          type: 'item',
          url: '/backoffice/subscription-package/list'
        }
      ]
    },
    {
      id: 'member',
      title: 'Member',
      type: 'collapse',
      icon: IconUserPlus,
      children: [
        {
          id: 'submission',
          title: 'Pengajuan',
          type: 'item',
          url: '/backoffice/member/submission'
        },
        {
          id: 'submission-detail',
          title: 'Detail Pengajuan',
          type: 'hidden',
          url: '^/backoffice/member/submission/[0-9]+$'
        },
        {
          id: 'verified',
          title: 'Terverifikasi',
          type: 'item',
          url: '/backoffice/member/verified'
        },
        {
          id: 'verified-detail',
          title: 'Detail Terverifikasi',
          type: 'hidden',
          url: '^/backoffice/member/verified/[0-9]+$'
        }
      ]
    },
    {
      id: 'payment',
      title: 'Pembayaran',
      type: 'collapse',
      icon: IconWallet,
      children: [
        {
          id: 'method',
          title: 'Metode Pembayaran',
          type: 'item',
          url: '/backoffice/payment/method'
        },
        {
          id: 'success',
          title: 'Pembayaran Sukses',
          type: 'item',
          url: '/backoffice/payment/success'
        },
        {
          id: 'pending',
          title: 'Pembayaran Pending',
          type: 'item',
          url: '/backoffice/payment/pending'
        },
        {
          id: 'failed',
          title: 'Pembayaran Gagal',
          type: 'item',
          url: '/backoffice/payment/failed'
        }
      ]
    },
    // {
    //   id: 'letter',
    //   title: 'Surat',
    //   type: 'item',
    //   url: '/backoffice/letter',
    //   icon: IconFileDescription
    // },
    {
      id: 'report',
      title: 'Report',
      type: 'item',
      url: '/backoffice/report',
      icon: IconReport
    },
    {
      id: 'log',
      title: 'Log',
      type: 'collapse',
      icon: IconDatabase,
      children: [
        {
          id: 'activity',
          title: 'Log Activity',
          type: 'item',
          url: '/backoffice/log/activity'
        },
        {
          id: 'api',
          title: 'Log API',
          type: 'item',
          url: '/backoffice/log/api'
        }
      ]
    },
    {
      id: 'setting',
      title: 'Konfigurasi',
      type: 'collapse',
      icon: IconSettings,
      children: [
        {
          id: 'user-type',
          title: 'Tipe User',
          type: 'item',
          url: '/backoffice/setting/user-type'
        },
        {
          id: 'sub-user-type',
          title: 'Sub Tipe User',
          type: 'hidden',
          url: '^/backoffice/setting/user-type/[0-9]+$'
        },
        {
          id: 'parking-provider',
          title: 'Parking Provider',
          type: 'item',
          url: '/backoffice/setting/parking-provider'
        },
        {
          id: 'faculty',
          title: 'Fakultas',
          type: 'item',
          url: '/backoffice/setting/faculty'
        },
        {
          id: 'system-param',
          title: 'Sistem Param',
          type: 'item',
          url: '/backoffice/setting/system-param'
        }
      ]
    }
  ]
};

export default backoffice;
