import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { getCache } from 'utils/local-storage';
import { Navigate } from 'react-router';

const Home = Loadable(lazy(() => import('views/frontoffice/home')));

const VehicleSubmission = Loadable(lazy(() => import('views/frontoffice/vehicle/submission')));
const VehicleList = Loadable(lazy(() => import('views/frontoffice/vehicle/list')));
const VehicleListDetail = Loadable(lazy(() => import('views/frontoffice/vehicle/list/detail')));

const Payment = Loadable(lazy(() => import('views/frontoffice/payment')));
const PaymentDetail = Loadable(lazy(() => import('views/frontoffice/payment/detail')));

const ParkingPackage = Loadable(lazy(() => import('views/frontoffice/parking-package')));

const HistorySubscription = Loadable(lazy(() => import('views/frontoffice/history-subscription')));

const accessToken = getCache('access_token');
const roleUser = getCache('role');
const currentUrl = window.location.href;

const renderElement = () => {
  if (/frontoffice/.test(currentUrl) && roleUser === 'ADM' && accessToken) {
    return <Navigate to="/backoffice/dashboard" />;
  } else if (roleUser === 'USR' && accessToken) {
    return <MainLayout />;
  } else {
    return <Navigate to="/login" />;
  }
};

const FrontofficeRoutes = {
  path: 'frontoffice',
  element: renderElement(),
  children: [
    {
      path: 'home',
      element: <Home />
    },
    {
      path: 'vehicle',
      children: [
        {
          path: 'submission',
          element: <VehicleSubmission />
        },
        {
          path: 'list',
          children: [
            {
              index: true,
              element: <VehicleList />
            },
            {
              path: ':id',
              element: <VehicleListDetail />
            }
          ]
        }
      ]
    },
    {
      path: 'parking-package',
      element: <ParkingPackage />
    },
    {
      path: 'payment',
      children: [
        {
          index: true,
          element: <Payment />
        },
        {
          path: ':id',
          element: <PaymentDetail />
        }
      ]
    },
    {
      path: 'history-subscription',
      element: <HistorySubscription />
    }
  ]
};

export default FrontofficeRoutes;
