import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { getCache } from 'utils/local-storage';
import { Navigate } from 'react-router';

// main menu
const Dashboard = Loadable(lazy(() => import('views/backoffice/dashboard')));

// Menu Pengguna
const UserAcademic = Loadable(lazy(() => import('views/backoffice/user/academic')));
const UserPartner = Loadable(lazy(() => import('views/backoffice/user/partner')));
const UserDetail = Loadable(lazy(() => import('views/backoffice/user/detail')));

// Menu Paket Langganan
const SubscriptionPackagePeriod = Loadable(lazy(() => import('views/backoffice/subscription-package/period')));
const SubscriptionPackagePeriodDetail = Loadable(lazy(() => import('views/backoffice/subscription-package/period/detail')));
const SubscriptionPackageList = Loadable(lazy(() => import('views/backoffice/subscription-package/list')));

// Menu Pengajuan Member
const MemberSubmission = Loadable(lazy(() => import('views/backoffice/member/submission')));
const MemberVerified = Loadable(lazy(() => import('views/backoffice/member/verified')));
const MemberDetail = Loadable(lazy(() => import('views/backoffice/member/detail')));

// Menu Pembayaran
const PaymentMethod = Loadable(lazy(() => import('views/backoffice/payment/method')));
const PaymentSuccess = Loadable(lazy(() => import('views/backoffice/payment/success')));
const PaymentPending = Loadable(lazy(() => import('views/backoffice/payment/pending')));
const PaymentFailed = Loadable(lazy(() => import('views/backoffice/payment/failed')));

// Menu Report
const Report = Loadable(lazy(() => import('views/backoffice/report')));

// Menu Log
const LogActivity = Loadable(lazy(() => import('views/backoffice/log/activity')));
const LogAPI = Loadable(lazy(() => import('views/backoffice/log/api')));

// Menu Konfigurasi
const SettingUserType = Loadable(lazy(() => import('views/backoffice/setting/user-type')));
const SettingSubUserType = Loadable(lazy(() => import('views/backoffice/setting/user-type/sub')));
const SettingParkingProvider = Loadable(lazy(() => import('views/backoffice/setting/parking-provider')));
const SettingFaculty = Loadable(lazy(() => import('views/backoffice/setting/faculty')));
const SettingSystemParam = Loadable(lazy(() => import('views/backoffice/setting/system-param')));

const accessToken = getCache('access_token');
const roleUser = getCache('role');
const currentUrl = window.location.href;
getCache('id_user');
getCache('name');
getCache('photo');

const renderElement = () => {
  if (/backoffice/.test(currentUrl) && roleUser === 'USR' && accessToken) {
    return <Navigate to="/frontoffice/home" />;
  } else if (roleUser === 'ADM' && accessToken) {
    return <MainLayout />;
  } else {
    return <Navigate to="/login" />;
  }
};

// ==============================|| MAIN ROUTING ||============================== //
const BackofficeRoutes = {
  path: 'backoffice',
  element: renderElement(),
  children: [
    {
      path: 'dashboard',
      element: <Dashboard />
    },
    {
      path: 'user',
      children: [
        {
          path: 'academic',
          element: <UserAcademic />
        },
        {
          path: 'partner',
          element: <UserPartner />
        },
        {
          path: ':usertype/:id',
          element: <UserDetail />
        }
      ]
    },
    {
      path: 'subscription-package',
      children: [
        {
          path: 'period',
          children: [
            {
              index: true,
              element: <SubscriptionPackagePeriod />
            },
            {
              path: ':id',
              element: <SubscriptionPackagePeriodDetail />
            }
          ]
        },
        {
          path: 'list',
          element: <SubscriptionPackageList />
        }
      ]
    },
    {
      path: 'member',
      children: [
        {
          path: 'submission',
          element: <MemberSubmission />
        },
        {
          path: 'verified',
          element: <MemberVerified />
        },
        {
          path: ':status/:id',
          element: <MemberDetail />
        }
      ]
    },
    {
      path: 'payment',
      children: [
        {
          path: 'method',
          element: <PaymentMethod />
        },
        {
          path: 'success',
          element: <PaymentSuccess />
        },
        {
          path: 'pending',
          element: <PaymentPending />
        },
        {
          path: 'failed',
          element: <PaymentFailed />
        }
      ]
    },
    {
      path: 'letter',
      element: <div>letter</div>
    },
    {
      path: 'report',
      element: <Report />
    },
    {
      path: 'log',
      children: [
        {
          path: 'activity',
          element: <LogActivity />
        },
        {
          path: 'api',
          element: <LogAPI />
        }
      ]
    },
    {
      path: 'setting',
      children: [
        {
          path: 'user-type',
          children: [
            {
              index: true,
              element: <SettingUserType />
            },
            {
              path: ':id',
              element: <SettingSubUserType />
            }
          ]
        },
        {
          path: 'parking-provider',
          element: <SettingParkingProvider />
        },
        {
          path: 'faculty',
          element: <SettingFaculty />
        },
        {
          path: 'system-param',
          element: <SettingSystemParam />
        }
      ]
    }
  ]
};

export default BackofficeRoutes;
