import { Link } from 'react-router-dom';
import '../assets/not-found.css';
import { getCache } from 'utils/local-storage';

const NotFound = () => {
  const roleUser = getCache('role');

  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>Oops!</h1>
        </div>
        <h2>404 - Page not found</h2>
        <p>The page you are looking for might have been removed had its name changed or unavailable.</p>
        <Link to={`${roleUser === 'ADM' ? '/backoffice/dashboard' : '/frontoffice/home'}`}>Go To Homepage</Link>
      </div>
    </div>
  );
};

export default NotFound;
