import { useRoutes } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import BackofficeRoutes from './BackofficeRoutes';
import FrontofficeRoutes from './FrontofficeRoutes';
import NotFound from 'views/NotFound';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    AuthenticationRoutes,
    BackofficeRoutes,
    FrontofficeRoutes,
    {
      path: '*',
      element: <NotFound />
    }
  ]);
}
