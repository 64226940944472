import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// project imports
import config from 'config';
import { MENU_OPEN } from 'store/actions';
import { getCache } from 'utils/local-storage';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();
  const roleUser = getCache('role');

  return (
    <ButtonBase disableRipple onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })} component={Link} to={config.defaultPath}>
      <Typography variant="h3">{roleUser === 'ADM' ? 'Manajemen Parkir' : 'Sistem Parkir UI'}</Typography>
    </ButtonBase>
  );
};

export default LogoSection;
